export function CadBanco(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Nome Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">IP Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Porta Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_name"
              value={props.formData?.t04_name || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_host"
              value={props.formData?.t04_host || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_port"
              value={props.formData?.t04_port || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuário Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Senha Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_user"
              value={props.formData?.t04_user || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_password"
              value={props.formData?.t04_password || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Nome Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">IP Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Porta Banco Seg</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_name"
              value={props.formData?.t04_seg_name || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_host"
              value={props.formData?.t04_seg_host || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_port"
              value={props.formData?.t04_seg_port || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuário Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Senha Banco Seg</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_user"
              value={props.formData?.t04_seg_user || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_passw"
              value={props.formData?.t04_seg_passw || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
