import { useEffect, useState } from 'react';

import { ApiMasterEmp } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { GridT04 } from './t04/GridT04';

export const CadastrarUsuarios = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataT04, setDataT04] = useState([]);
  const [formData, setFormData] = useState({});
  const [titleContexto, setTitleContexto] = useState('');
  const [contexto, setContexto] = useState('');

  const handleSearchEmpresa = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setDataT04(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (ambiente = '', id = '') => {
    try {
      setTitleContexto(`Ambiente: ${ambiente} - Contexto: ${id}`);
      setContexto(id || contexto);
      setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleReset = () => {
  //   setFormData({});
  //   setActiveTab(2);
  // };

  const handleSave = async () => {
    alert('Em atualização');
    // const body = {
    //   k92_depusr: formData.k92_depusr || null,
    //   k92_matric: formData.k92_matric || null,
    //   k92_nivusr: formData.k92_nivusr || null,
    //   k92_nomusr: formData.k92_nomusr || null,
    //   k92_orgusr: formData.k92_orgusr || null,
    //   k92_senha: formData.k92_senha || null,
    //   k92_tipusr: formData.k92_tipusr || null,
    //   k92_userid: formData.k92_userid || null,
    //   contexto: contexto
    // };
    // try {
    //   // const response = await NewApiPost(
    //   //   `/v1/suporte/k92`,
    //   //   body,
    //   //   localStorage.getItem('sessionToken')
    //   // );
    //   // if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
    //   //   alert(response?.data?.mensagem?.msg);
    //   //   return;
    //   // }
    //   alert('Usuário registrado com sucesso');
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   handleSearch();
    //   handleReset();
    // }
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Cadastrar-Usuarios') {
      setActiveTab(1);
      handleSearchEmpresa();
    }
  }, [props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Cadastrar-Usuarios"
      tabIndex="-1"
      aria-labelledby="lblUsuarios"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblUsuarios"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Cadastrar Usuários ${titleContexto && `- ${titleContexto}`}`}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Cadastrar-Usuarios' && (
            <div className="modal-body">
              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Grid da T04</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 && 'active'}`}
                  id="tab1"
                >
                  <GridT04
                    setDataT04={setDataT04}
                    dataT04={dataT04}
                    handleSearch={handleSearch}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 && 'active'}`}
                  id="tab2"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                  />
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setTitleContexto('');
                  setActiveTab(1);
                }}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-check"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
