import { useEffect, useState } from 'react';

import { ApiGet, ApiMasterEmp } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';
import { GridT04 } from './t04/GridT04';

export const Ocorrencias = (props) => {
  const today = new Date();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const datinclFinal = formatDate(today);
  const datinclInicial = formatDate(
    new Date(today.setDate(today.getDate() - 30))
  );

  const [activeTab, setActiveTab] = useState(1);
  const [dataT04, setDataT04] = useState([]);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [dataFiltro, setDataFiltro] = useState({
    datinclFinal,
    datinclInicial
  });
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [title, setTitle] = useState('');
  const [titleContexto, setTitleContexto] = useState('');
  const [ambiente, setAmbiente] = useState('');
  const [contexto, setContexto] = useState('');

  const handleSearchEmpresa = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setDataT04(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (ambiente = '', id = '') => {
    let datincl = null;

    const conditionDatmovInicial =
      dataFiltro.datinclInicial !== '' &&
      dataFiltro.datinclInicial !== null &&
      dataFiltro.datinclInicial !== undefined;
    const conditionDatmovFinal =
      dataFiltro.datinclFinal !== '' &&
      dataFiltro.datinclFinal !== null &&
      dataFiltro.datinclFinal !== undefined;

    if (conditionDatmovInicial && conditionDatmovFinal) {
      datincl = [dataFiltro.datinclInicial, dataFiltro.datinclFinal];
    }

    try {
      const response = await ApiGet(
        '/v1/suporte/ocorrencia',
        {
          contexto: id || contexto,
          where: {
            ocr_codoco: dataFiltro.ocr_codoco || '',
            ocr_tipoco: dataFiltro.ocr_tipoco || '',
            ocr_ident1: dataFiltro.ocr_ident1 || '',
            ocr_ident2: dataFiltro.ocr_ident2 || '',
            ocr_ident3: dataFiltro.ocr_ident3 || '',
            ocr_tipident1: dataFiltro.ocr_tipident1 || '',
            ocr_tipident2: dataFiltro.ocr_tipident2 || '',
            ocr_tipident3: dataFiltro.ocr_tipident3 || '',
            ocr_datincl: datincl || ''
          }
        },
        localStorage.getItem('sessionToken')
      );
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (ambiente !== '' && id !== '') {
        setAmbiente(ambiente);
        setContexto(id);
      }

      setData(response.data.dados);
      setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setTitle('');
    setFormData({});
    setActiveTab(2);
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Ocorrencias') {
      setActiveTab(1);
      handleSearchEmpresa();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (ambiente !== '' && contexto !== '') {
      setTitleContexto(`Ambiente: ${ambiente} - Contexto: ${contexto}`);
    }
  }, [ambiente, contexto]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Ocorrencias"
      tabIndex="-1"
      aria-labelledby="lblUsuarios"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblUsuarios"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Ocorrências ${titleContexto && `- ${titleContexto}`} ${
                title && `- ${title}`
              }`}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Ocorrencias' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Grid da T04</p>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 && 'active'}`}
                  id="tab1"
                >
                  <GridT04
                    setDataT04={setDataT04}
                    dataT04={dataT04}
                    handleSearch={handleSearch}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 && 'active'}`}
                  id="tab2"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setViewOrUpdate={setViewOrUpdate}
                    dataEmpresa={props.dataEmpresa}
                    setTitle={setTitle}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 && 'active'}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                    dataEmpresa={props.dataEmpresa}
                  />
                </div>
              </div>
            </div>
          )}

          {(activeTab === 2 || activeTab === 3) && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (activeTab === 2) {
                    setTitleContexto('');
                    setActiveTab(1);
                  } else handleReset();
                }}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
              </button>

              {activeTab === 2 && (
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTransferencia"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
