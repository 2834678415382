import { useEffect, useState } from 'react';

import { ApiMasterEmp } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { Search } from './navegacao/Search';

export const Empresa = (props) => {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [dataFiltro, setDataFiltro] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [title, setTitle] = useState('');

  const handleSearch = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setData(sortedData);
      setDataFilter(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    const body = {
      t04_codorg: formData.t04_codorg || '',
      t04_nomorg: formData.t04_nomorg || '',
      t04_sigorg: formData.t04_sigorg || '',
      t04_host: formData.t04_host || '',
      t04_port: formData.t04_port || '',
      t04_name: formData.t04_name || '',
      t04_user: formData.t04_user || '',
      t04_password: formData.t04_password || '',
      t04_seg_host: formData.t04_seg_host || '',
      t04_seg_port: formData.t04_seg_port || '',
      t04_seg_name: formData.t04_seg_name || '',
      t04_seg_user: formData.t04_seg_user || '',
      t04_seg_passw: formData.t04_seg_passw || '',
      t04_area: formData.t04_area || '',
      t04_porta_app: formData.t04_porta_app || '',
      t04_api_key: formData.t04_api_key || '',
      t04_email_atd: formData.t04_email_atd || '',
      t04_email_com: formData.t04_email_com || '',
      t04_type: formData.t04_type || '',
      t04_language: formData.t04_language || '',
      t04_layout: formData.t04_layout || '',
      t04_usedef_endpoints: formData.t04_usedef_endpoints || '',
      t04_storageendpoint: formData.t04_storageendpoint || '',
      t04_access_key_id: formData.t04_access_key_id || '',
      t04_secret_access_key: formData.t04_secret_access_key || '',
      t04_region: formData.t04_region || '',
      t04_bucket: formData.t04_bucket || '',
      t04_ambient_obj: formData.t04_ambient_obj || '',
      t04_bucket_res: formData.t04_bucket_res || '',
      t04_bucket_chk: formData.t04_bucket_chk || '',
      t04_nivel: formData.t04_nivel || '',
      t04_tela_chk: formData.t04_tela_chk || '',
      t04_crianca_chk: formData.t04_crianca_chk || '',
      t04_qtd_adult_chk: formData.t04_qtd_adult_chk || 0,
      t04_qtd_dias_chk: formData.t04_qtd_dias_chk || 0,
      t04_ambiente: formData.t04_ambiente || '',
      t04_ass_digital: formData.t04_ass_digital || '',
      t04_meta_5s: formData.t04_meta_5s || 0
    };

    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await ApiMasterEmp('post', '/v1/auth/', body);
      } else {
        response = await ApiMasterEmp('put', '/v1/auth/', body);
      }

      if (response.status === 200 || response.status === 201) {
        if (insertOrUpdate === 'insert') {
          alert('Empresa registrada com sucesso');
        } else {
          alert('Empresa atualizada com sucesso');
        }
      } else {
        if (insertOrUpdate === 'insert') {
          alert('Erro ao registrar empresa');
          return;
        } else {
          alert('Erro ao atualizar empresa');
          return;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSearch();
      handleReset();
    }
  };

  const handleReset = () => {
    setTitle('');
    setFormData({});
    setActiveTab(1);
  };

  const handleFiltro = () => {
    setDataFilter(
      data.filter((item) => {
        return (
          (!dataFiltro.sigorg || item.t04_sigorg === dataFiltro.sigorg) &&
          (!dataFiltro.codorg || item.t04_codorg === dataFiltro.codorg) &&
          (!dataFiltro.nomorg ||
            item.t04_nomorg.includes(dataFiltro.nomorg.toUpperCase())) &&
          (!dataFiltro.ambiente || item.t04_ambiente === dataFiltro.ambiente)
        );
      })
    );
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Empresa') {
      setActiveTab(1);
      handleSearch();
    }
  }, [props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Empresa"
      tabIndex="-1"
      aria-labelledby="lblEmpresas"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblEmpresas"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Empresa ${title && `- ${title}`}`}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Empresa' && (
            <div className="modal-body">
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Search
                    dataFiltro={dataFiltro}
                    setDataFiltro={setDataFiltro}
                    handleFiltro={handleFiltro}
                  />
                </div>
              </div>

              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 && 'active'}`}
                  id="tab1"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    setTitle={setTitle}
                    dataFiltro={dataFiltro}
                    dataFilter={dataFilter}
                    setDataFilter={setDataFilter}
                    handleReset={handleReset}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 && 'active'}`}
                  id="tab2"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    tamTexto={props.tamTexto}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            {activeTab === 1 && (
              <>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTransferencia"
                  aria-controls="offcanvasExample"
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setTitle('Adicionar');
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
            )}

            {activeTab === 2 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReset}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button>

                {viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
