import { useEffect, useState } from 'react';

import { ApiGet, ApiMasterEmp } from '../../services/apiService';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { GridT04 } from './t04/GridT04';

export const ContasAuditoria = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataT04, setDataT04] = useState([]);
  const [data, setData] = useState([]);
  const [titleContexto, setTitleContexto] = useState('');

  const handleSearchEmpresa = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setDataT04(sortedData);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearch = async (ambiente, id) => {
    try {
      const response = await ApiGet(
        '/v1/suporte/contas/auditoria',
        { contexto: id },
        localStorage.getItem('sessionToken')
      );
      if (response?.data?.mensagem?.msg?.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      setTitleContexto(`Ambiente: ${ambiente} - Contexto: ${id}`);
      setActiveTab(2);
      setData(response.data.dados);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setData([]);
    setTitleContexto('');
    setActiveTab(1);
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Contas-Auditoria') {
      setActiveTab(1);
      handleSearchEmpresa();
    }
  }, [props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Contas-Auditoria"
      tabIndex="-1"
      aria-labelledby="lblAuditoriaContas"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblAuditoriaContas"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Auditoria de Contas Duplicadas ${
                titleContexto && `- ${titleContexto}`
              }`}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Contas-Auditoria' && (
            <div className="modal-body">
              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Grid da T04</p>
                <p style={{ display: 'none' }}>Navegação</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 && 'active'}`}
                  id="tab1"
                >
                  <GridT04
                    setDataT04={setDataT04}
                    dataT04={dataT04}
                    handleSearch={handleSearch}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 && 'active'}`}
                  id="tab2"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleReset}
                style={{ fontSize: props.tamTexto }}
              >
                <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
