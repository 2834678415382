export function CadCheckin(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Layout</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Telas Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Criança Chk</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_layout"
              value={props.formData?.t04_layout || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_tela_chk"
              value={props.formData?.t04_tela_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_crianca_chk"
              value={props.formData?.t04_crianca_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Qtd. Adulto Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Qtd. Dias Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Assinatura Digital</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_qtd_adult_chk"
              value={props.formData?.t04_qtd_adult_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_qtd_dias_chk"
              value={props.formData?.t04_qtd_dias_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ass_digital"
              value={props.formData?.t04_ass_digital || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Access Key Id</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Secret Access Key</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Região</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_access_key_id"
              value={props.formData?.t04_access_key_id || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_secret_access_key"
              value={props.formData?.t04_secret_access_key || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_region"
              value={props.formData?.t04_region || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Bucket</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Bucket Res.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Bucket Chk.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket"
              value={props.formData?.t04_bucket || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket_res"
              value={props.formData?.t04_bucket_res || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket_chk"
              value={props.formData?.t04_bucket_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Ambiente Obj.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ambient_obj"
              value={props.formData?.t04_ambient_obj || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
