import { useEffect, useState } from 'react';

import {
  ApiGet,
  ApiMasterEmp,
  ApiPut,
  NewApiPost
} from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';
import { GridT04 } from './t04/GridT04';

export const Usuarios = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabCadastro, setActiveTabCadastro] = useState(1);
  const [dataT04, setDataT04] = useState([]);
  const [data, setData] = useState([]);
  const [dataK93, setDataK93] = useState([]);
  const [formData, setFormData] = useState({});
  const [formDataK93, setFormDataK93] = useState({});
  const [insertOrUpdate, setInsertOrUpdate] = useState('');
  const [insertOrUpdateK93, setInsertOrUpdateK93] = useState('');
  const [viewOrUpdate, setViewOrUpdate] = useState('');
  const [viewOrUpdateK93, setViewOrUpdateK93] = useState('');
  const [title, setTitle] = useState('');
  const [titleK93, setTitleK93] = useState('');
  const [titleContexto, setTitleContexto] = useState('');
  const [contexto, setContexto] = useState('');

  const handleSearchEmpresa = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setDataT04(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (ambiente = '', id = '') => {
    const idFormat = id || contexto;

    try {
      const response = await ApiGet(
        '/v1/suporte/k92',
        { contexto: idFormat },
        localStorage.getItem('sessionToken')
      );
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      const sortedData = response.data.dados.sort((a, b) => {
        if (a.k92_userid < b.k92_userid) return -1;
        if (a.k92_userid > b.k92_userid) return 1;
        return 0;
      });

      setTitleContexto(`Ambiente: ${ambiente} - Contexto: ${id}`);
      setData(sortedData);
      setContexto(id || contexto);
      setActiveTab(2);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = () => {
    setTitle('');
    setFormData({});
    setActiveTab(2);
  };

  const handleSave = async () => {
    const body = {
      k92_depusr: formData.k92_depusr || null,
      k92_matric: formData.k92_matric || null,
      k92_nivusr: formData.k92_nivusr || null,
      k92_nomusr: formData.k92_nomusr || null,
      k92_orgusr: formData.k92_orgusr || null,
      k92_senha: formData.k92_senha || null,
      k92_tipusr: formData.k92_tipusr || null,
      k92_userid: formData.k92_userid || null,
      contexto: contexto
    };

    let response = null;

    try {
      if (insertOrUpdate === 'insert') {
        response = await NewApiPost(
          `/v1/suporte/k92`,
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/v1/suporte/k92`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdate === 'insert') {
        alert('Conta registrada com sucesso');
      } else {
        alert('Conta atualizada com sucesso');
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSearch();
      handleReset();
    }
  };

  const handleSaveK93 = async () => {
    const body = {
      k93_userid: formDataK93.k93_userid || null,
      k93_system: formDataK93.k93_system || null,
      k93_codniv: formDataK93.k93_codniv || null,
      k93_admini: formDataK93.k93_admini || null,
      k93_nivant: formDataK93.k93_nivant || null,
      k93_datexp: formDataK93.k93_datexp || null,
      k93_sdatexp: formDataK93.k93_sdatexp || null,
      k93_horlim: formDataK93.k93_horlim || null,
      k93_shorlim: formDataK93.k93_shorlim || null,
      contexto: contexto
    };

    let response = null;

    try {
      if (insertOrUpdateK93 === 'insert') {
        response = await NewApiPost(
          `/v1/suporte/k93`,
          body,
          localStorage.getItem('sessionToken')
        );
      } else {
        response = await ApiPut(
          `/v1/suporte/k93`,
          body,
          localStorage.getItem('sessionToken')
        );
      }

      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      if (insertOrUpdateK93 === 'insert') {
        alert('K93 registrada com sucesso');
      } else {
        alert('K93 atualizada com sucesso');
      }

      const response2 = await ApiGet(
        '/v1/suporte/k93',
        { contexto: contexto },
        localStorage.getItem('sessionToken')
      );
      if (response2?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response2?.data?.mensagem?.msg);
        return;
      }

      const sortedData = response2.data.dados
        .filter((i) => formData.k92_userid === i.k93_userid)
        .sort((a, b) => {
          if (a.k93_userid < b.k93_userid) return -1;
          if (a.k93_userid > b.k93_userid) return 1;

          if (a.k93_system < b.k93_system) return -1;
          if (a.k93_system > b.k93_system) return 1;

          return 0;
        });

      setDataK93(sortedData);
      setFormDataK93({});
      setActiveTabCadastro(2);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Usuarios') {
      setActiveTab(1);
      handleSearchEmpresa();
    }
  }, [props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Usuarios"
      tabIndex="-1"
      aria-labelledby="lblUsuarios"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblUsuarios"
              style={{ fontSize: props.tamTitulo }}
            >
              {`Usuários ${titleContexto && `- ${titleContexto}`} ${
                title && `- ${title} ${titleK93 && `| ${titleK93}`}`
              }`}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Usuarios' && (
            <div className="modal-body">
              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Grid da T04</p>
                <p style={{ display: 'none' }}>Navegação</p>
                <p style={{ display: 'none' }}>Cadastro</p>
              </div>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === 1 && 'active'}`}
                  id="tab1"
                >
                  <GridT04
                    setDataT04={setDataT04}
                    dataT04={dataT04}
                    handleSearch={handleSearch}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 2 && 'active'}`}
                  id="tab2"
                >
                  <CtrNavegacao
                    data={data}
                    setData={setData}
                    setFormData={setFormData}
                    setDataK93={setDataK93}
                    setActiveTab={setActiveTab}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    setInsertOrUpdate={setInsertOrUpdate}
                    setViewOrUpdate={setViewOrUpdate}
                    handleSearch={handleSearch}
                    dataEmpresa={props.dataEmpresa}
                    setTitle={setTitle}
                    contexto={contexto}
                    setActiveTabCadastro={setActiveTabCadastro}
                  />
                </div>
                <div
                  className={`tab-pane ${activeTab === 3 && 'active'}`}
                  id="tab3"
                >
                  <Cadastro
                    formData={formData}
                    setFormData={setFormData}
                    viewOrUpdate={viewOrUpdate}
                    dataK93={dataK93}
                    setDataK93={setDataK93}
                    formDataK93={formDataK93}
                    setFormDataK93={setFormDataK93}
                    setActiveTab={setActiveTab}
                    setInsertOrUpdateK93={setInsertOrUpdateK93}
                    setViewOrUpdateK93={setViewOrUpdateK93}
                    insertOrUpdateK93={insertOrUpdateK93}
                    viewOrUpdateK93={viewOrUpdateK93}
                    setTitleK93={setTitleK93}
                    contexto={contexto}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    dataEmpresa={props.dataEmpresa}
                    activeTabCadastro={activeTabCadastro}
                    setActiveTabCadastro={setActiveTabCadastro}
                    insertOrUpdate={insertOrUpdate}
                  />
                </div>
              </div>
            </div>
          )}

          {(activeTab === 2 || activeTab === 3) && (
            <div className="modal-footer">
              {activeTabCadastro !== 3 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (activeTab === 2) {
                      setTitleContexto('');
                      setActiveTab(1);
                    } else handleReset();
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button>
              )}

              {activeTabCadastro === 3 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setTitleK93('');
                    setActiveTabCadastro(2);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button>
              )}

              {activeTab === 2 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setTitle('Adicionar');
                    setInsertOrUpdate('insert');
                    setViewOrUpdate('update');
                    setActiveTab(3);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              )}

              {activeTab === 3 &&
                activeTabCadastro === 1 &&
                viewOrUpdate !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}

              {activeTab === 3 &&
                activeTabCadastro === 3 &&
                viewOrUpdateK93 !== 'view' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveK93}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
