export function CadOutros(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Porta App</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Api Key</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Email Atd</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_porta_app"
              value={props.formData?.t04_porta_app || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_api_key"
              value={props.formData?.t04_api_key || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_email_atd"
              value={props.formData?.t04_email_atd || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Email Com</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Type</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Linguagem</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_email_com"
              value={props.formData?.t04_email_com || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_type"
              value={props.formData?.t04_type || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_language"
              value={props.formData?.t04_language || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usedef Endpoints</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Storage Endpoint</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_usedef_endpoints"
              value={props.formData?.t04_usedef_endpoints || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_storageendpoint"
              value={props.formData?.t04_storageendpoint || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
