import { ApiDelete, ApiGet } from '../../../services/apiService';
import { Grid } from './Grid';

export const CtrNavegacao = (props) => {
  const handleDelete = async (id) => {
    try {
      const response = await ApiDelete('/v1/suporte/k92', {
        id,
        contexto: props.contexto
      });
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      alert('Registro deletado com sucesso');
      props.handleSearch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (id, item) => {
    try {
      const response = await ApiGet(
        '/v1/suporte/k93',
        { contexto: id },
        localStorage.getItem('sessionToken')
      );
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      const sortedData = response.data.dados
        .filter((i) => item.k92_userid === i.k93_userid)
        .sort((a, b) => {
          if (a.k93_userid < b.k93_userid) return -1;
          if (a.k93_userid > b.k93_userid) return 1;

          if (a.k93_system < b.k93_system) return -1;
          if (a.k93_system > b.k93_system) return 1;

          return 0;
        });

      props.setDataK93(sortedData);
      props.setActiveTabCadastro(1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
      setTitle={props.setTitle}
      setActiveTab={props.setActiveTab}
      setFormData={props.setFormData}
      handleEdit={handleEdit}
      id={props.contexto}
    />
  );
};
