import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Config from '../modal/Config';
import i18n from '../services/i18n/i18n';

function Header(props) {
  const { t } = useTranslation();

  const [bgNavbar, setBgNavbar] = useState('#0d6efd');
  const [fontNavbar, setFontNavbar] = useState('#ffffff');

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLogout = () => {
    if (localStorage.getItem('sessionToken')) {
      localStorage.removeItem('sessionToken');
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  const srcImg = () => {
    switch (selectedLanguage) {
      case 'ptBR':
        return 'imagens/brasil.png';
      case 'en':
        return 'imagens/usa.jpg';
      default:
        return 'imagens/espanha.png';
    }
  };

  useEffect(() => {
    const savedConfigString = localStorage.getItem('config');
    if (savedConfigString) {
      const savedConfig = JSON.parse(savedConfigString);
      setBgNavbar(savedConfig.bgNavbar);
      setFontNavbar(savedConfig.fontNavbar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <nav
      className="navbar navbar-expand-md"
      style={{ backgroundColor: bgNavbar }}
    >
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <img src="imagens/logo.png" alt="logo SALT" height="40" />
          <p
            className="navbar-brand ms-3 navbar-center"
            style={{ color: fontNavbar, fontSize: props.tamTitulo }}
          >
            SALT - SIE SUPORTE
          </p>

          {props.login && (
            <ul className="navbar-nav">
              <li
                className="nav-item ms-3 mt-1 navbar-center"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                Empresa: {props.dataEmpresa.nomorg} {t('Usuario')}:{' '}
                {localStorage.getItem('sessionNome')}
              </li>
            </ul>
          )}
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <i
                className="fa fa-solid fa-gear nav-link navbar-center"
                data-bs-toggle="modal"
                data-bs-target="#Config"
                style={{ color: fontNavbar }}
              ></i>
              <Config
                bgNavbar={bgNavbar}
                fontNavbar={fontNavbar}
                selectedLanguage={selectedLanguage}
                setBgNavbar={setBgNavbar}
                setFontNavbar={setFontNavbar}
                setSelectedLanguage={setSelectedLanguage}
                area={props.area}
                login={props.login}
                bgMenu={props.bgMenu}
                setArea={props.setArea}
                tamInput={props.tamInput}
                fontMenu={props.fontMenu}
                tamTexto={props.tamTexto}
                abr_area={props.abr_area}
                setBgMenu={props.setBgMenu}
                tamTitulo={props.tamTitulo}
                campoArea={props.campoArea}
                dataBanco={props.dataBanco}
                setTamInput={props.setTamInput}
                setFontMenu={props.setFontMenu}
                setTamTexto={props.setTamTexto}
                tamSubTexto={props.tamSubTexto}
                changeState={props.changeState}
                tamSubTitulo={props.tamSubTitulo}
                setTamTitulo={props.setTamTitulo}
                setCampoArea={props.setCampoArea}
                setDataBanco={props.setDataBanco}
                setTamSubTexto={props.setTamSubTexto}
                setChangeState={props.setChangeState}
                area_verificar={props.area_verificar}
                setTamSubTitulo={props.setTamSubTitulo}
              />
            </li>
            <li className="nav-itemr">
              <p className="nav-link navbar-center" onClick={handleLogout}>
                <i
                  className="fa fa-solid fa-right-from-bracket"
                  style={{ color: fontNavbar }}
                ></i>
              </p>
            </li>
            <li className="nav-item">
              <img src={srcImg()} className="mt-2 img-nav" alt="Logo Brasil" />
            </li>
            <li className="nav-item">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: '0.8em' }}
              >
                V 1.01.11B
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
