import { useState } from 'react';

import { CadAuditoria } from './CadAuditoria';
import { CadBanco } from './CadBanco';
import { CadCheckin } from './CadCheckin';
import { CadOutros } from './CadOutros';
import { CadT04 } from './CadT04';

export function Cadastro(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);
  const [activeTab3, setActiveTab3] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ fontSize: props.tamTexto }}
          >
            T04
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ fontSize: props.tamTexto }}
          >
            Banco
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadT04
              handleInput={handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tabCadastro2"
          >
            <CadBanco
              handleInput={handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab2 === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab2(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Checkin Online
          </p>
          <p
            className={`nav-link ${
              activeTab2 === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab2(2)}
            style={{ fontSize: props.tamTexto }}
          >
            Auditoria
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab2 === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadCheckin
              handleInput={handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
          <div
            className={`tab-pane ${activeTab2 === 2 ? 'active' : ''}`}
            id="tabCadastro2"
          >
            <CadAuditoria
              handleInput={handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
        </div>
      </div>

      <div className="col-12 mt-3">
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab3 === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab3(1)}
            style={{ fontSize: props.tamTexto }}
          >
            Outros
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab3 === 1 ? 'active' : ''}`}
            id="tabCadastro1"
          >
            <CadOutros
              handleInput={handleInput}
              formData={props.formData}
              setFormData={props.setFormData}
              viewOrUpdate={props.viewOrUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
