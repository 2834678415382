import { CadK92 } from './CadK92';
import { CadK93 } from './CadK93';
import { GridK93 } from './GridK93';

export const Cadastro = (props) => {
  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  const handleInputK93 = (event) => {
    const { name, value } = event.target;
    props.setFormDataK93({ ...props.formDataK93, [name]: value });
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            props.activeTabCadastro === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => props.setActiveTabCadastro(1)}
          style={
            props.activeTabCadastro === 3
              ? { display: 'none' }
              : { fontSize: props.tamTexto }
          }
        >
          K92
        </p>
        {props.insertOrUpdate !== 'insert' && (
          <>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 2
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => props.setActiveTabCadastro(2)}
              style={
                props.activeTabCadastro === 3
                  ? { display: 'none' }
                  : { fontSize: props.tamTexto }
              }
            >
              K93
            </p>
            <p style={{ display: 'none' }}>Cadastro K93</p>
          </>
        )}
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 1 ? 'active' : ''
          }`}
          id="tabCadastro1"
        >
          <CadK92
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>

        {props.insertOrUpdate !== 'insert' && (
          <>
            <div
              className={`tab-pane ${
                props.activeTabCadastro === 2 ? 'active' : ''
              }`}
              id="tabCadastro2"
            >
              <GridK93
                tamTexto={props.tamTexto}
                tamSubTexto={props.tamSubTexto}
                setTitleK93={props.setTitleK93}
                setActiveTab={props.setActiveTab}
                dataK93={props.dataK93}
                setDataK93={props.setDataK93}
                formDataK93={props.formDataK93}
                setFormDataK93={props.setFormDataK93}
                setInsertOrUpdateK93={props.setInsertOrUpdateK93}
                setViewOrUpdateK93={props.setViewOrUpdateK93}
                insertOrUpdateK93={props.insertOrUpdateK93}
                viewOrUpdateK93={props.viewOrUpdateK93}
                activeTabCadastro={props.activeTabCadastro}
                setActiveTabCadastro={props.setActiveTabCadastro}
              />
            </div>

            <div
              className={`tab-pane ${
                props.activeTabCadastro === 3 ? 'active' : ''
              }`}
              id="tabCadastro3"
            >
              <CadK93
                handleInputK93={handleInputK93}
                formDataK93={props.formDataK93}
                setFormDataK93={props.setFormDataK93}
                viewOrUpdateK93={props.viewOrUpdateK93}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
