export function CadAuditoria(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Meta 5S</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_meta_5s"
              value={props.formData?.t04_meta_5s || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
