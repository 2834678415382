import { useState } from 'react';

import { CadastrarUsuarios } from '../modal/cadastrarUsuarios/CadastrarUsuarios';
import { ContasAuditoria } from '../modal/contas_auditoria/ContasAuditoria';
import { ContasDuplicadas } from '../modal/contas_duplicadas/ContasDuplicadas';
import { Empresa } from '../modal/empresas/Empresas';
import { K93 } from '../modal/k93/K93';
import { Ocorrencias } from '../modal/ocorrencias/Ocorrencias';
import { Usuarios } from '../modal/usuarios/Usuarios';
import { handleCard } from './handleCard';

export const Card = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    backgroundColor: isHovered ? '#cfe5ed' : props.bgMenu,
    color: props.fontMenu
  };

  return (
    <div
      className="card cursor cardHover w-100 mt-1 mb-1"
      tabIndex="-1"
      data-bs-toggle="modal"
      data-bs-target={props.modal}
      style={cardStyle}
      onClick={() => {
        if (props.texto === 'Endpoints') {
          window.open('https://sie.app.br:21080/api-docs', '_blank');
        } else {
          props.onClick();
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <h4 className="card-title">
          <i
            className={props.icon}
            style={{ fontSize: props.tamSubTitulo }}
          ></i>
        </h4>
        <h5
          className="card-text text-center fw-semibold"
          style={{ fontSize: props.tamSubTitulo }}
        >
          {props.texto}
        </h5>
      </div>
    </div>
  );
};

function Menu(props) {
  const [isOpen, setIsOpen] = useState('');

  const cards = handleCard();

  return (
    <div className="mt-1" style={{ width: '95vw', marginLeft: '2.5vw' }}>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '1';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '2';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '3';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
      </div>

      {props.login ? (
        <>
          <Empresa
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <Usuarios
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <Ocorrencias
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <K93
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <ContasAuditoria
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <ContasDuplicadas
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />

          <CadastrarUsuarios
            isOpen={isOpen}
            tamTexto={props.tamTexto}
            tamTitulo={props.tamTitulo}
            tamSubTexto={props.tamSubTexto}
          />
        </>
      ) : (
        <div
          className="modal fade"
          id="msgError"
          tabIndex="-1"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <div>Não Logado</div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
