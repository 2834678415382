export function CadT04(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Chave (sigorg)</span>
          </div>
          <div className="col-6">
            <span className="col-form-label">Cód. (codorg)</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_sigorg"
              value={props.formData?.t04_sigorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_codorg"
              value={props.formData?.t04_codorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Nome (nomorg)</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_nomorg"
              value={props.formData?.t04_nomorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Área</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Nível</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Ambiente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_area"
              value={props.formData?.t04_area || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_nivel"
              value={props.formData?.t04_nivel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ambiente"
              value={props.formData?.t04_ambiente || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
